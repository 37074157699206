import React from "react";

import Spellet from "../images/spellet2.png"
import Vindatabase from "../images/vindatabase.png"
import Loadbalancer from "../images/loadbalancer.png"
import Moloen from "../images/moloenPage.png"

const PorteføljeBoks = (props) => {
	let bildeDict = {"Moloen":Moloen,"Østkantspellet":Spellet,"Vindatabase":Vindatabase,"Lastfordeler":Loadbalancer};
	let tagDict = {"JavaScript":"whiteSpan badge-warning","React":"badge-info","Sanity":"badge-success orange","Gatsby":"badge-info gatsby",
			"Python":"badge-success python","OpenFlow":"badge-success","PHP":"badge-danger","SQL":"badge-primary"};
	let linkDict = {
		"Moloen": [
			"",
			"https://www.moloen.no"
		],
		"Østkantspellet":[
			"",
			"https://www.spellet.no"
		],
		"Lastfordeler":[
			"https://github.com/Andreasaarrestad/loadbalancer",
			""
		],
		"Vindatabase":[
			"https://github.com/Andreasaarrestad/vindatabase",
			"http://35.228.242.86/wine2/index.php"
		]
	}


	return (
	<div className="porteføljeDiv0" style={{marginTop:"100px",padding:0,position:"relative",width:"100%",height:"400px"}}>
		<div className="porteføljeBilde3" style={props.left ?  {filter:"grayscale(100%)",backgroundSize:"cover",backgroundImage:" linear-gradient(0deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.1) 100%),url(" + bildeDict[props.title] +")",position:"absolute",width:"600px",height:"100%",right:0} : {filter:"grayscale(100%)",backgroundSize:"cover",backgroundImage:" linear-gradient(0deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.1) 100%),url(" + bildeDict[props.title] +")",position:"absolute",width:"600px",height:"100%"}}></div>

		<h3 style={props.left ? {top:"20px",position:"absolute",left:0} : {top:"20px",position:"absolute",right:0}}><b>{props.title}</b></h3>
		<div style={props.left ? {top:"60px",position:"absolute",left:0} : {top:"60px",position:"absolute",right:0}} >

			{props.tags.map(function(name,index) {
				return <span key={index} className={"badge porteføljeBadge " + tagDict[name] + ""}>{name}</span>;
			})}


		</div>
		<div className="porteføljetekstboks" style={props.left ? {margin:0,padding:"20px 30px 20px 30px",position:"absolute",color:"black",backgroundColor:"white",width:"60%",
				top:"25%",left:0} : {margin:0,padding:"20px 30px 20px 30px",position:"absolute",color:"black",backgroundColor:"white",width:"60%",
				top:"25%",right:0}}>


				{props.children}



				<span style={props.left ? {position:"absolute",bottom:"-50px",fontSize:"24px",left:0} : {position:"absolute",bottom:"-50px",fontSize:"24px",right:0}}>


					<a href={linkDict[props.title][0]}><i className={props.github ? "porteføljeLink fab fa-github" : ""}></i></a>
					<a href={linkDict[props.title][1]}><i style={{marginLeft:"10px"}} className={props.web ? "porteføljeLink fas fa-external-link-alt" : ""}></i></a>
				</span>



		</div>

	</div>
)
}

export default PorteføljeBoks
