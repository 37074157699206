import { Link } from "gatsby"
import React from "react"


import styles from './header.module.css'


class Header extends React.Component {

	constructor() {
		super();
		this.state = {
			clicked:false
		};

		this.changeState = this.changeState.bind(this);

		this.burgerRef = React.createRef();
	}



	changeState() {

		const clicked2 = this.state.clicked;

		this.setState({clicked: !clicked2});


	}

	render() {

		return (
			<>

			   <nav style={{paddingLeft:"5%",paddingRight:"5%"}}>

				<div style={{display:"flex",width:"100%",justifyContent:"space-between"}}>

					   <div  onClick={this.changeState} className={this.state.clicked ? styles.ulGrey : styles.ulGreyCollapsed} style={{position:"fixed",left:0,width:"100%",height:"100vh",zIndex:"9"}}></div>
					   <ul id="navDiv4" className={this.state.clicked ? styles.ulNav : styles.ulNavCollapsed} style={{marginLeft:"auto",marginTop:"5px"}}>


							<li data-sal="flip-down" data-sal-delay="800" data-sal-easing="ease" className="nav-item">
					                  <a   onClick={this.changeState} className="nav-link HeaderLink" href="#ommeg"><code>01.</code> Om meg</a>
					                </li>
					                <li data-sal="flip-down" data-sal-delay="700" data-sal-easing="ease" className="nav-item ">
					                  <a  onClick={this.changeState} className="HeaderLink nav-link " href="#ferdigheter"><code>02.</code> Ferdigheter</a>
					                </li>
					                <li data-sal="flip-down" data-sal-delay="600" data-sal-easing="ease" className="nav-item">
					                  <a  onClick={this.changeState} className="nav-link HeaderLink" href="#tidslinje"><code>03.</code> Tidslinje</a>
					                </li>
					                <li data-sal="flip-down" data-sal-delay="500" data-sal-easing="ease" className="nav-item">
					                  <a  onClick={this.changeState} className="nav-link HeaderLink" href="#portefølje"><code>04.</code> Portefølje</a>
					                </li>

							 <li data-sal="flip-down" data-sal-delay="400" data-sal-easing="ease" className="nav-item">
							<a id="btnNav" class="nav-link navButton btn-slice" href="mailto:andreasaarrestad@gmail.com">
						      <div class="top"><span>Kontakt meg</span></div>
						      <div class="bottom"><span>Kontakt meg</span></div>
						      </a>
						      </li>




					   </ul>
					   <a onClick={this.changeState} className={styles.burger}>
					   <i  style={{fontSize:"25px",marginTop:"27.5px"}} className="fas fa-bars"></i>
						</a>
				</div>

			   </nav>
		   </>
		)
	}
}


export default Header
