import React from "react"
import styles from './boks2.module.css'


const Boks2 = (props) => {
	return (
	<div  className={styles.div} >

		{(props.first == true) &&
			<div data-sal="slide-right" data-sal-delay="900" data-sal-easing="ease">


			<div  style={{display:"flex"}}>

				<h2 style={props.white ? {color:"white"} : {}}><code style={{fontSize:"32px"}}>{props.number}</code> {props.boksheader}</h2>
				<hr style={props.white ? {width:"30%",height:"0.1px",backgroundColor:"white",marginLeft:"20px",marginTop:"22px"} : {width:"30%",height:"0.1px",backgroundColor:"black",marginLeft:"20px",marginTop:"22px"}} />
			</div>
		</div>
		}

		{(!props.first == true) &&
			<div>


			<div  style={{display:"flex"}}>

				<h2 style={props.white ? {color:"white"} : {}}><code style={{fontSize:"32px"}}>{props.number}</code> {props.boksheader}</h2>
				<hr style={props.white ? {width:"30%",height:"0.1px",backgroundColor:"white",marginLeft:"20px",marginTop:"22px"} : {width:"30%",height:"0.1px",backgroundColor:"black",marginLeft:"20px",marginTop:"22px"}} />
			</div>
		</div>
		}

		{props.children}

	</div>


)
}

export default Boks2
