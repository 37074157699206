import React from "react"
import SEO from "../components/seo"
import {Helmet} from "react-helmet"

import Boks from '../components/Boks'
import Boks2 from '../components/Boks2'
import SomeBoks from '../components/someBoks'
import BoksHeader from '../components/BoksHeader'
import ProgressBoks from '../components/progressBoks'
import Header from '../components/header'
import PorteføljeBoks from '../components/porteføljeBoks'

import CV from "./CV.pdf"


import arre from '../images/arre2.jpg'
import wine from '../images/wine.svg'
import fire from '../images/drama.svg'
import hub from '../images/hub.svg'

import TimelineLi from '../components/timelineLi'


const Index = () => {

  return (
    <>

    <SEO title="Andreas Aarrestad | Sivilingeniørstudent og konsulent" description="Andreas Aarrestad er en 21-åring bosatt i Trondheim som driver med digital konsultering, webutvikling og programmering. "/>
    <div style={{position:"relative",width:"100%"}}>
	<Header/>
        <div className="background1">

          <div style={{position:"relative",zIndex:"1",paddingTop:"90px"}}className="wrapper">
            <div id="row" className="row ">
              <div className="col-12">
                <h1 data-sal="flip-down" data-sal-delay="300" data-sal-easing="ease" id="navn"><b>Andreas Aarrestad</b></h1>
                <h2 data-sal="flip-down" data-sal-delay="400" data-sal-easing="ease"><b>Sivilingeniørstudent og konsulent</b></h2>
		 </div>



              <div id="some2" className="col-xs-12 col-sm-5 col-md-5 col-xl-5">

              </div>
            </div>

        </div>





      </div>


        <div id="ommeg"></div>

	<Boks2 first delay="900" number="01." boksheader="Om meg">
	<div  data-sal="slide-up" data-sal-delay="1000" data-sal-easing="ease" >
            <div  className="row " style={{position:"relative",marginTop:"50px"}}>
	    <div id="omtekstdiv" className="col-xl-7 col-lg-7 col-md-6 col-sm-12 col-xs-12 order-2 order-xs-2 order-sm-2 order-md-1" >



	      <p>Jeg er en 21-åring fra Skien, nå bosatt i Trondheim. Her studerer jeg tredjeåret mot en mastergrad i
	       kommunikasjonsteknologi og digital sikkerhet ved NTNU Gløshaugen. Som student har jeg stor
	       interesse for både programmering og matematikk, men det er selve skjæringspunktet mellom teknologi, økonomi
	       og forretninger jeg brenner for. Fra studiene har jeg et godt grunnlag med emner som statistikk, calculus, fysikk og
	       algoritmekonstruksjon som jeg bygger på med emner som digital økonomi, anvendt nettverksteori og digital sikkerhet i IKT-systemer.<br/><br/>

		Ved siden av
		studiene har jeg og tre medstudenter også opprettet vårt eget konsulentselskap der vi driver med webutvikling og digital rådgivning.
		Det har vært svært lærerikt og givende å få jobbe i team og få praktisk erfaring fra både det rent tekniske,
		samt å lære å forholde seg til kunder og drive forretninger.<br/><br/>

		Som person er jeg nøyaktig, utadvendt og arbeidsom, og ikke redd for nye utfordringer. Håper du som leser tar kontakt dersom det er noe
		du tror jeg kan bidra med!



		<br/><br/>

		<a class="nav-link navButton btn-slice btnblack" href={CV}>
	      		<div class="top"><span>LAST NED CV</span></div>
	      		<div class="bottom"><span>LAST NED CV</span></div>
	      	</a>

	       <div style={{display:"flex",marginTop:"30px"}}>
	       <a title="LinkedIn" className="someLink" href="https://www.linkedin.com/in/andreasaarrestad/"><h3 style={{marginTop:"-8px",fontSize:"33px"}}><i class="fab fa-linkedin"></i></h3></a>
	     	<a title="Facebook" className="someLink" href="https://www.facebook.com/andreas.aarr"><h3 style={{marginTop:"-8px",fontSize:"33px",marginLeft:"30px"}}><i class="fab fa-facebook"></i></h3></a>
	     	<a title="Instagram" className="someLink" href="https://www.instagram.com/andreasaarrestad/"><h3 style={{marginTop:"-8px",fontSize:"33px",marginLeft:"30px"}}><i class="fab fa-instagram"></i></h3></a>
	     	<a title="Github" className="someLink" href="https://github.com/Andreasaarrestad"><h3 style={{marginTop:"-8px",fontSize:"33px",marginLeft:"30px"}}><i class="fab fa-github"></i></h3></a>
</div>


	      </p>

	    </div>
	      <div id="bildeDiv3" style={{position:"relative",height:"400px"}} className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-xs-12 order-1 order-xs-1 order-sm-1 order-md-2">
              	<div id="bildeDiv" style={{width:"300px",position:"absolute",right:"50%",marginRight:"-135px"}}>

		        <img id="bildeDiv2" style={{width:"300px",zIndex:5,position:"absolute",borderColor:" #ADD8E6",borderStyle:"solid"}} src={arre} alt="andreas"/>
		        <div id="firkantDiv" style={{zIndex:4,position:"absolute",borderColor:" #ADD8E6",width:"300px",height:"300px",borderStyle:"solid"}} ></div>
		</div>
</div>
            </div>
</div>
</Boks2>



        <div id="ferdigheter"></div>



		<div  id="ferdighetDiv">

			<Boks2 delay="600" white number="02." boksheader="Ferdigheter">

		            <div className=" contentDiv">


			                <div className="row">



						<div className="col-xs-12 col-md-6 col-lg-3">
						  <ProgressBoks delay="100" top="70px" header1="Utvikling" header2="programmering" width="90%" bilde="prog">
							Med emner som algoritmekonstruksjon og databaseutforming fikk jeg revolusjonert
							tilnærmingen til programmering og utvikling. Fra etableringen av selskapet vårt som fokuserer på webutvikling og digital rådgivning har jeg lært å strukturere
							arbeidet og jobbe godt i team. <br/><br/>
							Jeg har god erfaring med språk som JavaScript, Python, Java, SQL og PHP, samt bruk av rammeverk og bibliotek som React, jQuery, Gatsby og Bootstrap.


						  </ProgressBoks>
						</div>

						<div className="col-xs-12 col-md-6 col-lg-3">
							<ProgressBoks delay="200" top="70px" header1="Nettverk" header2="sikkerhet" width="85%" bilde="sec">
								Mastergraden har et stort fokus på anvendt nettverksteori og informasjonssikkerhet. Her har jeg fått en avansert og utfyllende
								innføring i internettarkitekturen, som jeg har brukt til å programmere <a className="underLine" href="#lastfordeler">rutere</a> og andre nettverkskomponenter. Parallellt har jeg gode kunnskaper
								om reaksjonære og preventive tiltak mot eventuelle sikkerhetstrusler, og kraftige modeller for nettverksrobusthet. <br/><br/>





							</ProgressBoks>
						</div>

						<div className="col-xs-12 col-md-6 col-lg-3">
							 <ProgressBoks delay="300" top="70px" header1="Økonomi" header2="analyse" width="65%" bilde="øko">
								Jeg har prosjekteringerfaring samt en teoretisk og praktisk bakgrunn for statistikkanalyser, trusselmodelleringer og risikoanalyser. I tillegg har
								jeg en grunnleggende forståelse for klassisk industriell økonomi, men også en spesiell kompetanse om den digitale økonomien. Den digitiale økonomien har brakt med
								seg nye
								forretningsmodeller som kan utnyttes med riktig kompetanse.




							</ProgressBoks>
						</div>

						<div className="col-xs-12 col-md-6 col-lg-3">
							<ProgressBoks delay="400" top="70px" header1="Design" header2="kommunikasjon" width="55%" bilde="design">
								Med et godt øye for estetikk forstår jeg hvor avgjørende design av en tjeneste er når det kommer til brukeropplevelsen. I selskapet har jeg
								hatt en fremtredende rolle spesielt i designfasen, noe som har vært ekstremt lærerikt. I tillegg har jeg på enkelte oppdrag vært ansvarlig
								for kundekontakten, noe som har lært meg viktigheten av hvordan et budskap kommuniseres.



							</ProgressBoks>
						</div>




			                </div>

			         </div>
			 </Boks2>


	</div>

        <div  id="tidslinje"></div>

        <Boks2 delay="600" number="03." boksheader="Tidslinje">
          <section className="timeline">
            <ul>
              <TimelineLi year="HØST 2018" desc="Sivilingeniøsstudium ved Gløshaugen i Trondheim som gir meg kompetanse i nettverk, digital sikkerhet og økonomi ved hjelp av emner som algoritmer, anvendt nettverksteori og sikkerhet.">
	      	Startet på mastergrad i <span id="komtek1">kommunikasjonsteknologi</span><span id="komtek2">kommunikasjons-teknologi</span> og digital sikkerhet
	      </TimelineLi>
              <TimelineLi  year="VÅR 2019" desc="Ukentlig læringsassistent for fem klasser ved Nidaros idrettsungdomsskole i Trondheim. Etter å ha gjennomført et pedagogikkurs holdt jeg undervisninger og hjalp elevene å løse oppgaver. ">
	      Læringsassistent for Vektorprogrammet
	       </TimelineLi>

	      <TimelineLi year="VÅR 2020" desc="Sammen med tre medstudenter etablerte jeg et eget konsultentselskap som fokuserer på webdesign og digital rådgivning.">
	      Opprettet Leaf Consulting
	       </TimelineLi>
	       <TimelineLi year="SOMMER 2020" desc="Ansvarlig for utvikling av ny løsning for eksport og import av hydrologiske og meteorologiske data fra målestasjoner ved bruk av API, samt utredet og utviklet en webtjener med Django for å samle overlappende tjenester under samme grensesnitt.">
 	      		Internship i Skagerak Energi
 	       </TimelineLi>
	       <TimelineLi year="HØST 2020" desc="Gitt i oppgave å veilede studenter og rette essays i emnet TTM4165 - Digital Økonomi. ">
		       Studentassisstent
	      </TimelineLi>

            </ul>
          </section>
	  </Boks2>



      <div id="portefølje" ></div>
      <div  id="porteføljeDiv" style={{paddingTop:"230px"}}>

	<Boks2 white number="04." boksheader="Portefølje">
	<PorteføljeBoks title="Moloen" tags={['JavaScript','PHP','SQL']} web>
	  	En dynamisk webside laget for eventbyrået Moloen AS gjennom konsulentselskapet mitt. Det ble etterspurt bruk av
		kun PHP og JavaScript, og inkluderer også et publiseringssystem med autorisering. Databasen blir aksessert med SQL.


  	</PorteføljeBoks>
	  <PorteføljeBoks left title="Østkantspellet" tags={['JavaScript','React','Sanity','Gatsby']} web>
		  En statisk webside laget for Østkantspellet gjennom konsulentselskapet mitt.
		  Siden er kodet i React, og er videre utvidet med Gatsby-rammeverket for forhåndsinnlastning av sider og optimalisering
		  av bildeinnlastning. I tillegg brukes Gatsby sammen med publiseringssystemet Sanity for å bygge siden etter at
		  det er gjort endringer i koden. Fra den egne adminsiden der kunden med et brukervennlig grensesnitt kan publisere
		  innlegg på siden, henter Gatsby ut innleggene fra databasen med GraphQL og legger dem til i DOM-en som React-objekter.

	  </PorteføljeBoks>
	  <PorteføljeBoks title="Lastfordeler" tags={['Python','OpenFlow']} github>
		  I forbindelse med en skoleoppgave for anvendt nettverksteori lagde jeg en lastfordeler for et simulert nettverk som ved hjelp av en round-robin-algoritme viderekobler
		  eventuelle forespørsler til en webtjeneste til en av mange tilgjengelige backend-servere. For å oppnå
		  en software-definert nettverksstruktur (SDN), brukes en kontroller til å styre lastfordeleren med
		  kommunikasjonsprotokollen OpenFlow. <br/><br/>

		  Lastfordeleren fungerer også som en proxy for backend-serverne ved å spoofe serveren sin MAC adresse til sin egen
		  når serveren svarer på ARP-forespørselen til klienten slik at hele lastfordelingen er usynlig for klienten.

	  </PorteføljeBoks>
	  <PorteføljeBoks left title="Vindatabase" tags={['JavaScript','PHP','SQL']} web github>
	  Egetmotivert prosjekt bygget med LAMP-stacken for å lage en database som kan gi en oversikt over ens vinbeholdning.
	  En vin legges
	  til i beholdningen ved å skrive inn vinens varenummer fra vinmonopolet sin database
	  og sender en HTTP-forespørsel
	   med varenummeret
	  som parameter til en av polets API-er, som returnerer et JSON-objekt med all informasjon om den gitte vinen.
	  Enkelte av disse feltene blir lagt til i min egen database, der vinobjektet blir lagret sammen med brukerens brukerID. Etterpå kan brukeren
	  i et eget vindu redigere antall viner, skrive en liten anmeldelse og gi en rangering på null til ti stjerner.<br/><br/>
	  Jeg lagde også en simpel innloggingsside for tjenesten med brukernavn og passord (som riktignok ikke er hasha) slik at de forskjellige brukerne ikke kan se hverandres vinkjellere.

	  </PorteføljeBoks>






	    </Boks2>

	    <div id="sisteBoks" style={{position:"relative",marginTop:"200px",width:"100%"}}>
	    	<h1  style={{marginBottom:"30px"}}className="text-center">Send meg gjerne en melding!</h1>

		<a style={{width:"110px",position:"absolute",left:"50%",marginLeft:"-55px"}} class="nav-link navButton btn-slice" href="mailto:andreasaarrestad@gmail.com">
	      <div class="top"><span>Kontakt</span></div>
	      <div class="bottom"><span>Kontakt</span></div>
	      </a>

	    </div>
	    </div>

        </div>





	    <Helmet>

	      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
	      <script src="https://code.jquery.com/jquery-3.4.1.slim.min.js" integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n" crossorigin="anonymous"></script>
	<script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js" integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo" crossorigin="anonymous"></script>
	<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js" integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6" crossorigin="anonymous"></script>
	      <script src="https://kit.fontawesome.com/ccac5df2b0.js" crossorigin="anonymous"></script>

	    </Helmet>





    </>
  )
}

export default Index
